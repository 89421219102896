import React from "react";
import "./Pricing.css"; // You can update this as needed
import { Link } from "react-router-dom";

const PricingPage = () => {
  return (
    <div className="pricing-page">
      <header className="pricing-header">
        <h1>Our Pricing Plans</h1>
        <p>
          Flexible plans for businesses of all sizes. From websites to graphic
          design, we’ve got you covered.
        </p>
      </header>

      {/* Pricing Cards */}
      <div className="pricing-container">
        {/* Website Design - Static */}
        <div className="pricing-card">
          <h2>Static Website</h2>
          <p className="price">$499</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Up to 5 Pages</li>
            <li>Responsive Design</li>
            <li>Basic SEO Setup</li>
            <li>Google Business Setup</li>
          </ul>
          <Link to="/services">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>

        {/* Website Design - Dynamic */}
        <div className="pricing-card">
          <h2>Dynamic Website</h2>
          <p className="price">$799</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Up to 10 Pages</li>
            <li>CMS Integration (WordPress/Custom)</li>
            <li>SEO Optimization</li>
            <li>Google Analytics Integration</li>
          </ul>
          <Link to="/services">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>

        {/* E-Commerce Website */}
        <div className="pricing-card">
          <h2>E-Commerce Website</h2>
          <p className="price">$1,299</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Unlimited Products</li>
            <li>Custom Shopping Cart</li>
            <li>Payment Gateway Integration</li>
            <li>SEO & Marketing Tools</li>
          </ul>
          <Link to="/services">
            <button className="cta-button">Contact Us</button>
          </Link>
        </div>

        {/* Graphic Design */}
        <div className="pricing-card">
          <h2>Graphic Design</h2>
          <p className="price">Starting at $299</p>
          <p className="plan-duration">Project-based pricing</p>
          <ul className="features">
            <li>Logo Design</li>
            <li>Business Cards</li>
            <li>Branding Kits</li>
            <li>Social Media Graphics</li>
          </ul>
          <Link to="/services">
            <button className="cta-button">Contact Us</button>
          </Link>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>

        {/* FAQ 1: Static Website */}
        <div className="faq">
          <h3>What is a static website, and when should I choose one?</h3>
          <p>
            A static website consists of fixed pages (up to 5), and is ideal for
            businesses that don't need frequent content updates. It's fast,
            secure, and cost-effective for small to medium-sized businesses that
            want a simple online presence.
          </p>
        </div>

        {/* FAQ 2: Dynamic Website */}
        <div className="faq">
          <h3>
            What is a dynamic website, and how is it different from a static
            website?
          </h3>
          <p>
            A dynamic website includes a content management system (CMS) like
            WordPress, which allows you to easily update your content without
            technical skills. It's perfect for businesses that need to update
            blog posts, product listings, or services regularly.
          </p>
        </div>

        {/* FAQ 3: E-commerce Website Features */}
        <div className="faq">
          <h3>What features are included in an e-commerce website?</h3>
          <p>
            An e-commerce website from InnoNative Solutions includes an
            integrated shopping cart, secure payment gateway (Stripe, PayPal),
            unlimited product listings, and built-in SEO tools to help you rank
            on search engines and increase online sales.
          </p>
        </div>

        {/* FAQ 4: Website Maintenance */}
        <div className="faq">
          <h3>Do you provide website maintenance and support?</h3>
          <p>
            Yes! We offer ongoing website maintenance starting at $99 per month.
            This includes regular updates, security patches, and ensuring your
            website runs smoothly.
          </p>
        </div>

        {/* FAQ 5: SEO Services */}
        <div className="faq">
          <h3>Do your websites come with SEO optimization?</h3>
          <p>
            All our websites include basic SEO features such as meta tags, alt
            text for images, and optimized page loading times. We also offer
            advanced SEO services to improve your search engine rankings.
          </p>
        </div>

        {/* FAQ 6: Timeframe */}
        <div className="faq">
          <h3>How long does it take to build a website?</h3>
          <p>
            The time to build a website depends on the complexity of the
            project. A static website can take between 1-2 weeks, while dynamic
            or e-commerce websites may take 3-6 weeks. We will provide a more
            accurate timeline after our initial consultation.
          </p>
        </div>

        {/* FAQ 7: Graphic Design Services */}
        <div className="faq">
          <h3>What graphic design services do you offer?</h3>
          <p>
            We offer logo design, business cards, full branding kits, social
            media graphics, and more. Our graphic design services start at $299
            and are tailored to meet your business’s unique identity and goals.
          </p>
        </div>

        {/* FAQ 8: Security */}
        <div className="faq">
          <h3>What security measures are included with your websites?</h3>
          <p>
            We implement SSL certificates, regular updates, and follow best
            practices for website security. Our e-commerce websites also include
            secure payment gateway integration to protect customer transactions.
          </p>
        </div>

        {/* FAQ 9: Custom Features */}
        <div className="faq">
          <h3>Can I request custom features for my website?</h3>
          <p>
            Yes, we can add custom features to meet your business needs, whether
            it's integrating third-party APIs, custom forms, or unique
            functionalities. Custom features will be quoted separately after
            discussing your requirements.
          </p>
        </div>

        {/* FAQ 10: Hosting */}
        <div className="faq">
          <h3>Do you offer hosting services?</h3>
          <p>
            We do not directly offer hosting services but can recommend reliable
            hosting providers and assist with the setup. If needed, we can
            manage your hosting as part of our maintenance package.
          </p>
        </div>

        {/* FAQ 11: Payment Terms */}
        <div className="faq">
          <h3>What are your payment terms?</h3>
          <p>
            We typically require a 50% deposit upfront and the remaining balance
            upon project completion. For larger projects, we can arrange
            milestone payments. Ongoing services like maintenance are billed
            monthly.
          </p>
        </div>

        {/* FAQ 12: Revisions */}
        <div className="faq">
          <h3>How many revisions are included in your design process?</h3>
          <p>
            We offer up to 3 rounds of revisions for each project to ensure
            you’re satisfied with the final result. Additional revisions can be
            provided at an hourly rate.
          </p>
        </div>

        {/* FAQ 13: Content Updates */}
        <div className="faq">
          <h3>Can I update content on my website after it's launched?</h3>
          <p>
            If you have a dynamic website with a CMS, you’ll be able to update
            content yourself easily. For static websites, we offer affordable
            content update packages to make changes for you when needed.
          </p>
        </div>

        {/* FAQ 14: Graphic Design Pricing */}
        <div className="faq">
          <h3>How do you price graphic design projects?</h3>
          <p>
            Our graphic design projects are priced based on complexity and
            deliverables. Basic packages (like logo design) start at $299, while
            larger branding kits or custom work will be quoted based on your
            needs.
          </p>
        </div>

        {/* FAQ 15: Scaling and Future Updates */}
        <div className="faq">
          <h3>Can my website be scaled or updated in the future?</h3>
          <p>
            Yes, all websites we build are designed to be scalable. Whether you
            want to add more pages, implement new features, or completely revamp
            your site, we can work with you on future updates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
